<script>
import {
  DatePicker,
  TimeSelect,
  Table,
  TableColumn,
  Select,
  Option,
} from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
  },
  data() {
    return {
      template_name: "",
      template_type: "",
      template_code: "",
      emailTemplates: [
        { value: 'marketing', label: 'Marketing' },
        { value: 'automation', label: 'Automation' },
      ],
      selectedTemplate: null,
      loading: false,
      temp_id: "",
      promocode: {
        promo_code: "",
        description: "",
        percentage: "",
        valid_upto: "",
        status: true,
      },
      errors: {
        promo_code: "",
        percentage: "",
        valid_upto: "",
      },
      temp_id: this.$route.query.id || "",  // Ensure temp_id is initialized

    };
  },
  created() {
    if (this.temp_id) {
      this.fetchTemplate(); // Fetch template if it's an edit (temp_id exists)
    }
  },
  methods: {
    fetchTemplate() {
      this.loading = true; // Show loading spinner

      this.$http
        .get(`/edit-email-template/${this.temp_id}`)  // Make the API request to fetch template by ID
        .then((response) => {
          if (response.data.success) {
            const template = response.data.data;
            this.template_name = template.template_name;
            this.template_type = template.template_type;
            this.template_code = template.template_code;
          } else {
            Swal.fire({
              title: "Error",
              text: response.data.message,
              icon: "error",
            });
          }
        })
        .finally(() => {
          this.loading = false; // Hide loading spinner
        });
    },
    saveTemplate() {
      if (this.temp_id !== "") {
        this.loading = true;
        const templateData = {
          template_name: this.template_name,
          template_type: this.template_type,
          ...(this.template_type === 'automation' && { template_code: this.template_code })
        };

        // Use PUT request for updating
        const id = this.$route.query.id;  // Get the template ID from query params
        this.$http
          .put(`/update-email-template/${id}`, templateData)
          .then((response) => {
            Swal.fire({
              title: "Success",
              text: "Template updated successfully.",
              icon: "success",
            });
            this.$router.push("/email_template");  // Redirect after success
          })
          .catch((error) => {
            if (error.response && error.response.data.errors) {
              const errorMessages = Object.values(error.response.data.errors)
                .flat()
                .join("\n");

              Swal.fire({
                title: "Validation Error",
                text: errorMessages,
                icon: "error",
              });
            } else {
              Swal.fire({
                title: "Error",
                text: "An unexpected error occurred.",
                icon: "error",
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        // this.loading = true;
        const data = {
          template_name: this.template_name,
          template_type: this.template_type,
          ...(this.template_type === 'automation' && { template_code: this.template_code })
        }
        this.$http
          .post("/save-email-template", {
            data
          })
          .then((resp) => {
            if (resp.data.success) {
              // Handle successful response
              Swal.fire({
                title: "Success!",
                text: resp.data.message || "Email template saved successfully.",
                icon: "success",
              });
              this.$router.push("/email_template"); // Adjust route as necessary
            } else {
              // Handle unexpected failure scenario
              Swal.fire({
                title: "Error!",
                text: "An unexpected error occurred. Please try again.",
                icon: "error",
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.data.errors) {
              // Extract and format error messages
              const errorMessages = Object.values(error.response.data.errors)
                .flat() // Flatten nested arrays of messages
                .map((msg, index) => `${index + 1}. ${msg}`) // Add numbering
                .join('\n'); // Join messages with a new line

              // Display errors in a Swal alert
              Swal.fire({
                title: "Validation Error",
                text: errorMessages,
                icon: "error",
              });
            } else if (error.response && error.response.data.message) {
              // Display general error message from the server
              Swal.fire({
                title: "Error",
                text: error.response.data.message,
                icon: "error",
              });
            } else {
              // Handle unexpected errors
              Swal.fire({
                title: "Error",
                text: "An unexpected error occurred. Please try again.",
                icon: "error",
              });
            }

            // Log the error for debugging purposes
            console.error('Errors:', error);
          })

          .finally(() => {
            this.loading = false; // Ensure loading is turned off
          });
      }

    },
  },
};
</script>
<template>
  <div class="content" v-loading.fullscreen.lock="loading">
    <base-header class="pb-6">
      <div class="row align-items-center py-2">
        <div class="col-lg-6 col-7"></div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card>
        <!-- Card header -->
        <h2 slot="header" class="mb-0" v-if="temp_id">Edit Email Template</h2>
        <h2 slot="header" class="mb-0" v-else>Add Email Template</h2>
        <!-- <validation-observer v-slot="{ handleSubmit }" ref="formValidator"> -->
        <form @submit.prevent="saveTemplate()" enctype="multipart/form-data">
          <div class="form-row">
            <div class="col-md-8">
              <label class="form-control-label">Template Name <span class="requireField">*</span></label>
              <base-input name="Template Name " placeholder="Template Name " v-model="template_name">
              </base-input>
              <!-- <span v-if="errors.promo_code" class="text-danger mb-1">{{ errors.promo_code }}</span> -->
            </div>

            <div class="col-md-8">
              <label class="form-control-label">Template Type <span class="requireField">*</span></label>
              <base-input>
                <el-select filterable placeholder="Select Template" v-model="template_type">
                  <el-option v-for="option in emailTemplates" class="select-primary" :value="option.value"
                    :label="option.label" :key="option.value">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-8" v-if="template_type === 'automation'">
              <label class="form-control-label">Template Code <span class="requireField">*</span></label>
              <base-input type="text" name="Template Code" placeholder="Template Code" max="100" min="0"
              v-model="template_code">
              </base-input>
            </div>
          </div>
          <div class="text-left mt-2">
            <base-button class="custom-btn" type="danger" @click="$router.go(-1)">Cancel</base-button>
            <base-button class="custom-btn" native-type="submit">{{
              temp_id !== "" ? " Update" : "Submit"
              }}</base-button>
          </div>
        </form>
        <!-- </validation-observer> -->
      </card>
    </div>
  </div>
</template>

<style></style>
