<script>
import { Option, Select, Table, TableColumn } from "element-ui";
import serverSidePaginationMixin from "../Tables/PaginatedTables/serverSidePaginationMixin";
import "sweetalert2/src/sweetalert2.scss";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
let timeout = null;
export default {
  name: "EmailMarketing",
  mixins: [serverSidePaginationMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loading: false,
        records:[],
        testEmail:"",
        testEmailModel:false,
        selectedTemplateId:""
    };
  },

  created () {
    this.fetchData();
    if (localStorage.getItem("hot-token")) {
      this.hot_user = localStorage.getItem("hot-user");
      this.hot_token = localStorage.getItem("hot-token");
    }

  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
    /* To get all the email templates */
    this.loading = true; // Show loading indicator

    this.$http
        .post("/get-email-template", {}) // API endpoint
        .then((response) => {
            if (response.data.success) {
                // Populate records array with fetched data
                this.records = response.data.data;
            } else {
                // Handle response with success: false
                Swal.fire({
                    title: "Error",
                    text: response.data.message,
                    icon: "error",
                });
            }
        })
        .finally(() => {
            this.loading = false; // Hide loading indicator
        });
    },


    deleteTemplate(id) {
      Swal.fire({
        title: "Delete Template?",
        text: "Are you sure you want to delete this template?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // API call to delete the template
          this.$http
            .delete(`/delete-email-template/${id}`) // Send DELETE request
            .then((response) => {
              Swal.fire("Deleted!", "The template has been deleted.", "success");
              this.fetchData(); // Refresh the table
            })
            .catch((error) => {
              Swal.fire("Error!", "Failed to delete the template.", "error");
              console.error("Error deleting template:", error);
            });
        }
      });
    },

    testEmailPreview(id){
      this.testEmailModel=true,
      this.selectedTemplateId=id;
    },
    sendTestEmail()
    {
      this.$http
            .post('send-test-email',{
              templateId : this.selectedTemplateId,
              testingMail : this.testEmail
            })
            .then((response) => {
              Swal.fire("Sent!", "Test email send successfully", "success");
              this.testEmail="";
              this.selectedTemplateId="";
              this.testEmailModel=false;

            })
            .catch((error) => {
              Swal.fire("Error!", "Failed to Send test Email.", "error");
              // console.error("Error deleting template:", error);
            });
    },
    setDefaultFilterData() {
      let previousStateData = JSON.parse(
        localStorage.getItem("promocode-filters")
      );
      if (previousStateData !== null) {
        this.searchQuery =
          previousStateData.search != undefined
            ? previousStateData.search
            : this.searchQuery;
        this.filters.status =
          previousStateData.status != undefined
            ? previousStateData.status
            : this.filters.status;
        this.perPage =
          previousStateData.per_page != undefined
            ? previousStateData.per_page
            : this.perPage;
      }

      this.fetchData();
    },

    changeStatus(index, row) {
      let prev_val = row.status;
      let status = "";
      if (prev_val) {
        status = 0;
      } else {
        status = 1;
      }
      let self = this;
      Swal.fire({
        title: "Are you sure?",
        text: "You want to change status!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .put(
                "/promocode/update_status/" + row.id,
                {
                  status: status,
                },
                self.config
              )
              .then((resp) => {
                this.fetchData();
                Swal.fire({
                  title: "Success!",
                  text: "Status has been Changed.",
                  icon: "success",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false,
                });
                self.tableData[index].status = !prev_val;
              });
          } else {
            self.tableData[index].status = prev_val;
          }
        })
        .catch(function () {
          self.tableData[index].status = prev_val;
        });
    },
  },
};
</script>
<template>
  <div>
    <div class="content">
      <div class="container-fluid mt-3">
        <card
          class="no-border-card"
          footer-classes="pb-2"
          v-loading.fullscreen.lock="loading"
        >
          <template slot="header">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-6 col-12">
                <h2 class="mb-0">Email Templates</h2>
              </div>
              <div class="col-lg-6 col-md-6 text-right">
                <router-link to="/create_email_template">
                  <base-button class="custom-btn"
                    ><i aria-hidden="true" class="fa fa-plus"></i>Add New Template</base-button
                  >
                </router-link>
              </div>
            </div>
          </template>
          <div>

            <div class="user-eltable">
              <el-table
                :data="records"
                class="compGrid"
                header-row-class-name="thead-light"
                highlight-current-row
                id="tbl1"
                role="table"
                row-key="id"
                stripe
              >
                <el-table-column label="Template Name" min-width="300px" prop="template_name">
                  <template slot-scope="props">
                    <span>{{ props.row.template_name }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="Template Type" min-width="350px" prop="template_type">
                  <template slot-scope="props">
                    <span>{{ props.row.template_type }}</span>
                  </template>
                </el-table-column>

                <el-table-column align="right" label="Actions" min-width="100px">
                  <div class="d-flex custom-size" slot-scope="{ row }">
                    <el-tooltip content="Edit" placement="top">
  <a 
    :href="'https://neon-florentine-1a1e36.netlify.app/?template_id=' + row.id" 
    target="_blank"
    rel="noopener noreferrer"
  >
    <base-button
      class="success"
      type=""
      size="sm"
      icon
      :data-toggle="'tooltip'"
      :data-original-title="'Edit'"
    >
      <i class="text-default fa fa-file"></i>
    </base-button>
  </a>
</el-tooltip>

                    <el-tooltip content="Edit" placement="top">
                      <router-link :to="'/create_email_template?id=' + row.id">
                        <base-button
                          class="success"
                          type=""
                          size="sm"
                          icon
                          data-toggle="tooltip"
                          data-original-title="Edit"
                        >
                          <i class="text-default fa fa-pencil-square-o"></i>
                        </base-button>
                      </router-link>
                    </el-tooltip>
                    <el-tooltip content="Delete" placement="top">
                      <base-button
                        @click.native="deleteTemplate(row.id)"
                        type=""
                        size="sm"
                        icon
                        data-toggle="tooltip"
                        data-original-title="Delete"
                      >
                        <i class="text-danger fa fa-trash"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip content="Email Test" placement="top">
                      <base-button
                        @click.native="testEmailPreview(row.id)"
                        type=""
                        size="sm"
                        icon
                        data-toggle="tooltip"
                        data-original-title="Email Test"
                      >
                      <i class="text-warning ni ni-email-83"></i>
                      </base-button>
                    </el-tooltip>

                  </div>
                </el-table-column>
              </el-table>
            </div>

          </div>

        </card>
      </div>
      <modal :show.sync="testEmailModel" class="user-modal">
      <h3 slot="header">Send Test Email</h3>
      <br />
      <div class="row">
        <div class="col-md-6">
        <label for="test-email">Enter Your Email</label>
        <input type="email" v-model="testEmail" class="form-control">
        <button class="btn btn-success mt-3" @click.prevent="sendTestEmail">Send Test Mail</button>
        </div>
      </div> <!-- Use v-html to bind and render HTML -->
    </modal>
    </div>
  </div>
</template>
