<template>
  <div class="content">
    <div class="container-fluid mt-3">
      <card class="no-border-card" footer-classes="pb-2" v-loading.fullscreen.lock="loading">
        <template slot="header">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-12">
              <h2 class="mb-0">Email Sending</h2>
            </div>
          </div>
        </template>

        <div>
          <!-- Radio Buttons -->
          <div class="row mb-3">
            <div class="col-12 d-flex justify-content-between">
              <!-- All Users -->
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="filterOptions"
                  id="allUsers"
                  value="allUsers"
                  v-model="selectedFilter"
                  @change="applyFilter"
                />
                <label class="form-check-label" for="allUsers">All Users</label>
              </div>

              <!-- All Admins -->
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="filterOptions"
                  id="allAdmins"
                  value="allAdmins"
                  v-model="selectedFilter"
                  @change="applyFilter"
                />
                <label class="form-check-label" for="allAdmins">All Admins</label>
              </div>

              <!-- All Managers -->
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="filterOptions"
                  id="allManagers"
                  value="allManagers"
                  v-model="selectedFilter"
                  @change="applyFilter"
                />
                <label class="form-check-label" for="allManagers">All Managers</label>
              </div>

              <!-- All Employees -->
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="filterOptions"
                  id="allEmployees"
                  value="allEmployees"
                  v-model="selectedFilter"
                  @change="applyFilter"
                />
                <label class="form-check-label" for="allEmployees">All Employees</label>
              </div>

              <!-- All Individuals -->
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="filterOptions"
                  id="allIndividuals"
                  value="allIndividuals"
                  v-model="selectedFilter"
                  @change="applyFilter"
                />
                <label class="form-check-label" for="allIndividuals">All Individuals</label>
              </div>

              <!-- Selected Users -->
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="filterOptions"
                  id="selectedUsers"
                  value="selectedUsers"
                  v-model="selectedFilter"
                  @change="applyFilter"
                />
                <label class="form-check-label" for="selectedUsers">Selected Users</label>
              </div>

              <!-- All Companies -->
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="filterOptions"
                  id="allCompanies"
                  value="allCompanies"
                  v-model="selectedFilter"
                  @change="applyFilter"
                />
                <label class="form-check-label" for="allCompanies">All Companies</label>
              </div>

              <!-- Selected Companies -->
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="filterOptions"
                  id="selectedCompanies"
                  value="selectedCompanies"
                  v-model="selectedFilter"
                  @change="applyFilter"
                />
                <label class="form-check-label" for="selectedCompanies">Selected Companies</label>
              </div>
            </div>
          </div>

          <!-- Selected Companies Section -->
          <div class="row" v-if="selectedFilter == 'selectedCompanies'">
            <div class="col-md-8">
              <base-input label="Select Companies">
                <el-select
                  style="width: 100%"
                  filterable
                  multiple
                  placeholder="Select Company"
                  remote
                  reserve-keyword
                  v-model="selectedCompanies"
                  :remote-method="fetchData"
                >
                  <el-option
                    v-for="(option, index) in companiesOption"
                    :key="'company' + index"
                    :value="option.id"
                    :label="option.name"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col-12">
              <strong>Companies : </strong> {{ company_count }}
              <br />
              <div class="col-md-8 d-flex justify-content-between mt-3">
                <!-- Start with All Admins, All Managers, then All Employees -->
                <el-checkbox v-model="CompaniesFilters.allAdmins">All Admins</el-checkbox>
                <el-checkbox v-model="CompaniesFilters.allManagers">All Managers</el-checkbox>
                <el-checkbox v-model="CompaniesFilters.allemmployees">All Employees</el-checkbox>
              </div>
            </div>
          </div>

          <!-- All Managers Count -->
          <div class="row mb-2" v-if="selectedFilter == 'allManagers'">
            <div class="col-12">
              <strong>Managers : </strong> {{ manager_count }}
            </div>
          </div>

          <!-- All Admins Count -->
          <div class="row mb-2" v-if="selectedFilter == 'allAdmins'">
            <div class="col-12">
              <strong>Admins : </strong> {{ admin_count }}
            </div>
          </div>

          <!-- All Companies Count -->
          <div class="row mb-2" v-if="selectedFilter == 'allCompanies'">
            <div class="col-12">
              <strong>Companies : </strong> {{ company_count }}
            </div>
          </div>

          <!-- All Users Count -->
          <div class="row mb-2" v-if="selectedFilter == 'allUsers'">
            <div class="col-12">
              <strong>Total Users : </strong> {{ all_count }}
            </div>
          </div>

          <!-- Selected Users Section -->
          <div class="row mb-2" v-if="selectedFilter == 'selectedUsers'">
            <div class="col-md-8">
              <base-input label="Select Users">
                <el-select
                  style="width: 100%"
                  filterable
                  multiple
                  remote
                  reserve-keyword
                  placeholder="Select Users"
                  v-model="selectedUsers"
                  :remote-method="remoteMethod"
                >
                  <el-option
                    v-for="(option, index) in userOptions"
                    :key="'user' + index"
                    :value="option.id"
                    :label="option.full_name"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <!-- Subject -->
          <div class="row">
            <div class="col-md-8">
              <base-input label="Subject">
                <el-input
                  v-model="subject"
                  placeholder="Enter subject"
                  class="input-primary"
                ></el-input>
              </base-input>
            </div>
          </div>

          <!-- Message (Vue Editor) -->
          <div class="row">
            <div class="col-md-8">
              <label for="">Message</label>
              <vue-editor v-model="content"></vue-editor>
            </div>
          </div>

          <!-- Email Template -->
          <div class="row">
            <div class="col-md-8">
              <base-input label="Email Template">
                <el-select
                  filterable
                  placeholder="Select Template"
                  v-model="selectedTemplate"
                >
                  <el-option
                    v-for="option in emailTemplates"
                    class="select-primary"
                    :value="option.id"
                    :label="option.template_name"
                    :key="option.id"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <!-- Actions -->
          <div class="row">
            <div class="col-md-6">
              <button @click.prevent="sendMail()" class="btn base-button btn-default px-3">
                Submit
              </button>
              <button @click.prevent="previewMail()" class="btn base-button btn-default px-3">
                Preview
              </button>
            </div>
          </div>

        </div>
      </card>
    </div>

    <!-- Modal for Email Preview -->
    <modal :show.sync="ifshowPreview" class="user-modal">
      <h3 slot="header">Preview Email</h3>
      <br />
      <div v-html="emailHtml"></div>
    </modal>
  </div>
</template>

<script>
import { Option, Select, Table, TableColumn, Checkbox } from "element-ui";
import serverSidePaginationMixin from "../Tables/PaginatedTables/serverSidePaginationMixin";
import "sweetalert2/src/sweetalert2.scss";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { VueEditor } from "vue2-editor";

let timeout = null;
export default {
  name: "resources",
  mixins: [serverSidePaginationMixin],
  components: {
    [Select.name]: Select,
    [Checkbox.name]: Checkbox,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VueEditor
  },
  data() {
    return {
      emailHtml: "",         // Store the email HTML content
      ifshowPreview: false,
      loading: false,
      selectedFilter: "",
      emailTemplates: [],    // Empty array to store templates dynamically
      selectedTemplate: null,
      content: "",
      subject: "",
      location: [],
      assigncompanies: [],
      manager_count: "",
      admin_count: "",
      all_count: "",
      company_count: "",
      selectedUsers: [],     // Selected user IDs
      userOptions: [],       // Options for the dropdown
      selectedCompanies: [], // Selected company IDs
      companiesOption: [],   // Options for the dropdown
      companysearchQuery: "", // User input for search
      searchQuery: "",        // User input for search
      CompaniesFilters: {
        allAdmins: true,
        allManagers: true,
        allemmployees: true
      }
    };
  },
  mounted() {},
  created() {
    // Fetch manager count
    this.$http.get("/manager-count").then((resp) => {
      this.manager_count = resp.data.manager_count;
    });
    // Fetch admin count
    this.$http.get("/admin-count").then((resp) => {
      this.admin_count = resp.data.admin_count;
    });
    // Fetch all users count
    this.$http.get("/all-count").then((resp) => {
      this.all_count = resp.data.all_count;
    });
    // Fetch company count
    this.$http.get("/company-count").then((resp) => {
      this.company_count = resp.data.all_company_count;
    });
    // Fetch marketing templates
    this.$http.get("/marketing-templates").then((resp) => {
      if (resp.data.success) {
        this.emailTemplates = resp.data.data; // Populate the emailTemplates array
      } else {
        Swal.fire({
          title: "Error",
          text: "Failed to load email templates.",
          icon: "error"
        });
      }
    });
  },
  methods: {
    applyFilter() {
      // This method can be used if any dynamic changes occur when switching filters
      // Currently it’s empty or can hold logic to reset certain states, etc.
    },

    async previewMail() {
      const filter = this.selectedFilter;
      if (!filter) {
        Swal.fire({
          title: "Missing!",
          text: "Please Select Any Filter",
          icon: "info"
        });
        return;
      }
      const data = {
        subject: this.subject,
        body: this.content,
        template: this.selectedTemplate
      };
      try {
        const response = await this.$http.post("/email-preview", { data });

        // Update the modal content and show it
        this.emailHtml = response.data.html; // Assign the HTML response
        this.ifshowPreview = true;           // Show the modal
      } catch (error) {
        console.error("Error fetching email preview:", error);
      }
    },

    fetchData(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.$http
            .get("/allcompanies", { params: { query } })
            .then((resp) => {
              this.companiesOption = resp.data;
            })
            .catch((error) => {
              console.error("Error fetching companies:", error);
            })
            .finally(() => {
              this.loading = false;
            });
        }, 400);
      } else {
        this.companiesOption = [];
      }
    },

    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.$http
            .get("/allusers", { params: { query } })
            .then((resp) => {
              this.userOptions = resp.data;
            })
            .catch((error) => {
              console.error("Error fetching users:", error);
            })
            .finally(() => {
              this.loading = false;
            });
        }, 400);
      } else {
        this.userOptions = [];
      }
    },

    sendMail() {
      const filter = this.selectedFilter;
      if (!filter) {
        Swal.fire({
          title: "Missing!",
          text: "Please Select Any Filter",
          icon: "info"
        });
        return;
      }
      // Dispatch mail method based on filter selection
      if (filter === "selectedUsers") {
        this.mailToSelectedUsers();
      } else if (filter === "allManagers") {
        this.mailToAllManagers();
      } else if (filter === "allUsers") {
        this.mailToAllUsers();
      } else if (filter === "selectedCompanies") {
        this.mailToSelectedCompanies();
      } else if (filter === "allCompanies") {
        this.mailToAllCompanies();
      } else if (filter === "allAdmins") {
        this.mailToAllAdmins();
      } else if (filter === "allEmployees") {
        this.mailToAllEmployees();
      } else if (filter === "allIndividuals") {
        this.mailToAllIndividuals();
      }
    },

    mailToAllAdmins() {
      const data = {
        subject: this.subject,
        body: this.content,
        template: this.selectedTemplate
      };
      this.$http
        .post("/send-email-all-admins", { data })
        .then((resp) => {
          Swal.fire({
            title: "Success!",
            text: resp.data.success,
            icon: "success"
          });
          this.resetFields();
        })
        .catch((error) => {
          this.handleErrors(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    mailToAllManagers() {
      const data = {
        users: this.allManagers,
        subject: this.subject,
        body: this.content,
        template: this.selectedTemplate
      };
      this.$http
        .post("/send-email-allmanagers", { data })
        .then((resp) => {
          Swal.fire({
            title: "Success!",
            text: resp.data.success,
            icon: "success"
          });
          this.resetFields();
        })
        .catch((error) => {
          this.handleErrors(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    mailToAllUsers() {
      const data = {
        users: this.allusers,
        subject: this.subject,
        body: this.content,
        template: this.selectedTemplate
      };
      this.$http
        .post("/send-email-all-users", { data })
        .then((resp) => {
          Swal.fire({
            title: "Success!",
            text: resp.data.success,
            icon: "success"
          });
          this.resetFields();
        })
        .catch((error) => {
          this.handleErrors(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    mailToAllEmployees() {
      const data = {
        subject: this.subject,
        body: this.content,
        template: this.selectedTemplate
      };
      this.$http
        .post("/send-email-all-employees", { data })
        .then((resp) => {
          Swal.fire({
            title: "Success!",
            text: resp.data.success,
            icon: "success"
          });
          this.resetFields();
        })
        .catch((error) => {
          this.handleErrors(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    mailToAllIndividuals() {
      const data = {
        subject: this.subject,
        body: this.content,
        template: this.selectedTemplate
      };
      this.$http
        .post("/send-email-all-individuals", { data })
        .then((resp) => {
          Swal.fire({
            title: "Success!",
            text: resp.data.success,
            icon: "success"
          });
          this.resetFields();
        })
        .catch((error) => {
          this.handleErrors(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    mailToSelectedUsers() {
      const data = {
        users: this.selectedUsers,
        subject: this.subject,
        body: this.content,
        template: this.selectedTemplate
      };
      this.$http
        .post("/send-email-selectedusers", { data })
        .then((resp) => {
          Swal.fire({
            title: "Success!",
            text: resp.data.success,
            icon: "success"
          });
          this.resetFields();
        })
        .catch((error) => {
          this.handleErrors(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    mailToSelectedCompanies() {
      const data = {
        companies: this.selectedCompanies,
        subject: this.subject,
        body: this.content,
        template: this.selectedTemplate,
        selectedCompanyFilters: this.CompaniesFilters
      };
      this.$http
        .post("/send-email-selected-companies", { data })
        .then((resp) => {
          Swal.fire({
            title: "Success!",
            text: resp.data.success,
            icon: "success"
          });
          this.resetFields();
        })
        .catch((error) => {
          this.handleErrors(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    mailToAllCompanies() {
      const data = {
        subject: this.subject,
        body: this.content,
        template: this.selectedTemplate
      };
      this.$http
        .post("/send-email-all-companies", { data })
        .then((resp) => {
          Swal.fire({
            title: "Success!",
            text: resp.data.success,
            icon: "success"
          });
          this.resetFields();
        })
        .catch((error) => {
          this.handleErrors(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    resetFields() {
      // Reset selected fields after sending mail
      this.selectedUsers = [];
      this.userOptions = [];
      this.selectedCompanies = [];
      this.companiesOption = [];
      this.searchQuery = "";
      this.content = "";
      this.subject = "";
    },

    handleErrors(error) {
      if (error.response && error.response.data.errors) {
        const errorMessages = Object.values(error.response.data.errors)
          .flat()
          .join("\n");
        Swal.fire({
          title: "Validation Error",
          text: errorMessages,
          icon: "error"
        });
      }
      console.error("Errors:", error);
    }
  }
};
</script>

<style scoped>
.no-border-card .card-footer {
  border-top: 0;
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .resourceGrid >>> table.el-table__body td:nth-of-type(1):before {
    content: "Resource Name";
  }
  .resourceGrid >>> table.el-table__body td:nth-of-type(2):before {
    content: "# Courses";
  }
  .resourceGrid >>> table.el-table__body td:nth-of-type(3):before {
    content: "Type";
  }
  .resourceGrid >>> table.el-table__body td:nth-of-type(4):before {
    content: "Available after completion";
  }
  .resourceGrid >>> table.el-table__body td:nth-of-type(5):before {
    content: "Status";
  }
  .resourceGrid >>> table.el-table__body td:nth-of-type(6):before {
    content: "Action";
  }
}
</style>
